.cardDescripcion{
    width: 25rem;
    margin-left: 33%;
    border-radius: 10px;
}

/** Forms **/

.iconoRegistro{
    width: 20%;
    text-align: end;
}

.formTitulo{
    font-size:20px;
    font-family: montserrat;
    width: 80%;
    text-align: start;
}

.form{
    padding:50px 40px 40px 40px ;
    background-color: rgb(255, 255, 255);
    margin: 40px auto;
    border-radius: 5px;
}

.containerLogin, .containerModificar{
    height: 510px;
}

.containerForm{
    display: flex;
}

/** Descripcion **/





@media(min-width: 480px){
    .containerRegistro, .containerAlta{
        height: 580px ;
    }

    .form{
        width: 400px;
    }

    .imgDescripcion img {
        width: 300px;
        height: 350px;
    }
    
    .buttonDescripcion{
        width: 300px;
        height: 50px;
        margin-top: 0.5rem;
    }
    
    .cajasDescripcion{
        padding: 2rem 1rem 1rem 1rem;
        background: linear-gradient( #61919f, #506e77);
        margin: 30px auto;
        width: 400px
    }
    
    .cajaTextDescripcion{
        font-family: montserrat;
        text-align: start;
        padding: 2rem 2rem 0 2rem ;
    }
    
    .descripcion{
        height: 1100px;
    }
    
    .tituloDescripcion{
        font-size: 17px;
    }

    .textDescripcion{
        font-size: 15px;
    }
    
    .alertCompra{
        height: 200px;
        margin: 200px auto
    }


}


@media(min-width: 768px){   
    .form{
        width: 600px;
    }

    .containerRegistro, .containerAlta{
        height: 600px ;
    }


    .imgDescripcion img {
        width: 200px;
        height: 250px;
    }
    
    .buttonDescripcion{
        width: 200px;
    }
    
    .cajasDescripcion{
        padding: 1.5rem 1.5rem 1.5rem 1.5rem;
        margin: 50px auto;
        display: flex;
        width: 600px;
    }
    
    .cajaTextDescripcion{
        padding: 0rem 0rem 0 1rem ;
    }
    
    .descripcion{
        height: 500px;
    }
    
    .tituloDescripcion{
        font-size: 15px;
    }

    .textDescripcion{
        font-size: 12px;
    }
    
}


@media(min-width: 992px){
    .imgDescripcion img {
        width: 300px;
        height: 350px;
    }
    
    .buttonDescripcion{
        width: 300px;
    }
    
    .cajasDescripcion{
        padding: 2rem 2rem 2rem 2rem;
        margin: 70px auto;
        width: 900px;
    }
    
    .cajaTextDescripcion{
        padding: 0rem 0rem 0 2rem ;
    }
    
    .descripcion{
        height: 550px;
    }
    
    .tituloDescripcion{
        font-size: 25px;
    }
    
    .alertCompra{
        height: 300px;
        margin: 200px auto
    }

    .textDescripcion{
        font-size: 16px;
    }
}


 