.containerLoading{
    margin: 300px auto;
    min-height: 1000px;
}

.menu{
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    font-family: montserrat;
}  

.menuContainer{
    background-color: #61919f;
}

.botonEnviar{
    width:200px
}

.footer{
    color: white;
    padding: 20px 0 0 0 ;
    height: 60px;
    font-size: 15px;
    background-color: #506e77;
    font-family: montserrat;
}

.inputBuscar{
    margin: 13px auto;
    width: 300px;
    justify-content: center;
    text-align: center;
    align-items: center;
}


.alertas{ 
    margin: 0 auto;
    width: 300px
}

.cardProducto{
    margin-bottom: 30px;
    border: 1.5px solid gray;
    padding: 10px;
    border-radius: 15px;
}

.brand{
    margin-left: 2rem
}


@media(min-width: 480px){
   

    .cajaCarousel{
        margin-top: 1rem;
        margin-bottom: 2rem;
    }
 
    .cardProductoImg{
           text-align: center;
            height: 300px;
         
    }
    
    .carousel  img {
        height: 200px;
    }
    
}

 @media(min-width: 584px){
    .cardProductoImg{
        height: 230px;
    }
 }

@media(min-width: 768px){

    .cajaCarousel{
        margin-bottom: 3rem;
    }
    
    .cardProductoImg{
        height: 260px;
        width: 150px;
    }
      
    .carousel  img {
        height: 400px;
    }
}

@media(min-width: 992px){

        .cardProductoImg{
            height: 270px;
        }
        
        .carousel  img {
            height: 500px;
        }
}


